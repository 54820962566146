import React, { forwardRef } from 'react'
import Paper from '@material-ui/core/Paper'
import Container from '@material-ui/core/Container'
import Button from '@material-ui/core/Button'
import Box from '@material-ui/core/Box'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import MenuItem from '@material-ui/core/MenuItem'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch from '@material-ui/core/Switch'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import CloseIcon from '@material-ui/icons/Close';
import './UserForm.scss';

const UserForm = forwardRef(({user, onSubmit, onChange, onClose}, ref) => {

  const isEditing = user && user.createdAt ? true : false;

  return (
    <Box
      ref={ref}
      className="Fullscreen UserForm"
      display="flex"
      alignItems="center"
      justifyContent="center">
      <Container maxWidth="sm">
        <Paper className="UserForm__panel">

          <Typography variant="h4">
            { isEditing ? 'Edit user' : 'Add new user' }
          </Typography>

          <IconButton
            className="UserForm__close"
            onClick={onClose}>
            <Tooltip title="Cancel">
              <CloseIcon />
            </Tooltip>
          </IconButton>

          <form onSubmit={onSubmit} autoComplete="off">

            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              required
              autoFocus
              autoComplete="off"
              type="text"
              label="Name"
              name="name"
              onChange={onChange}
              placeholder="John Smith"
              value={user.name || ''} />

            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              required
              autoComplete="off"
              type="text"
              name="email"
              label="Email"
              onChange={onChange}
              placeholder="email@domain"
              value={user.email || ''} />

            <TextField
              className="UserForm__select"
              variant="outlined"
              margin="normal"
              fullWidth
              required
              autoComplete="off"
              select
              label="Role"
              name="role"
              onChange={onChange}
              value={user.role || ''}>
              <MenuItem value="User">User</MenuItem>
              <MenuItem value="Editor">Editor</MenuItem>
              <MenuItem value="Admin">Admin</MenuItem>
            </TextField>

            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              required
              autoComplete="off"
              type="text"
              label="Username"
              name="username"
              onChange={onChange}
              placeholder="jsmith"
              value={user.username || ''} />

            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              required
              autoComplete="new-password"
              type="password"
              label="Password"
              name="password"
              onChange={onChange}
              placeholder="Password"
              value={user.password || ''} />

            {isEditing &&
              <FormControlLabel
                control={
                  <Switch
                    checked={user.active || ''}
                    name="active"
                    color="primary"
                    value="true"
                    onChange={onChange} />
                }
                label="Enabled" />
            }

            <Button
              className="UserForm__submit"
              type="submit"
              variant="outlined"
              color="primary">
              Save
            </Button>

          </form>

        </Paper>
      </Container>
    </Box>
  )
});


export default UserForm
