import React, { Component } from 'react'
import { Grid } from '@material-ui/core'
import Welcome from './Welcome'
import Loading from '../components/loading/Loading';

import { loadCrown } from '../api';
import withStore from '../store';
import track, { setIdentity } from '../track';

const ShowError = () => {
  // TODO: More user friendly error message
  return (
    <Grid
      container
      alignContent="center"
      alignItems="center"
      justify="center"
      className="Container fullscreen white-bg"
    >
      <p>An error occurred</p>
    </Grid>
  )
}

class Home extends Component {

    constructor(props) {
      super(props);
      const urlParams = new URLSearchParams(window.location.search);
      this.state = {
        uid: urlParams.get('uid'),
        hasLoaded: false,
        crown: null,
        error: null,
      }
    }

    componentDidMount() {
      this.handleLoadPage();
    }

    handleLoadPage = () => {
      this.handleFetchCrown();
    }

    handleFetchCrown = async () => {
      // Get URL query params
      const { store, updateStore } = this.props;
      const urlParams = new URLSearchParams(window.location.search);
      const uid = urlParams.get('uid');
      const sku = urlParams.get('sku');
      if (!store.firstLoadTimestamp) {
        try {
          setIdentity(uid);
          track('first load', uid);
          track('SKU', sku);
          track('user agent', window.navigator.userAgent);
          track('browser vendor', window.navigator.vendor);
          track('screen size', `${window.screen.width}x${window.screen.height}`);
          track('languages', window.navigator.languages.join(', '));
          track('share support', 'share' in window.navigator);
        } catch(error) {
          // let slip
        }
        updateStore({ firstLoadTimestamp: new Date().toISOString() });
      }
      if (!uid) {
        return this.setState({ hasLoaded: true });
      }
      const crown = await loadCrown({ uid });
      if (!crown) {
        this.setState({ hasLoaded: true, isError: true });
        return;
      }
      this.setState({
        crown,
        hasLoaded: true,
      })
    }

    render() {
      const { crown, error, hasLoaded } = this.state;
      if (error) {
        return (<ShowError />);
      }
      if (!hasLoaded) {
        return (
          <Loading />
        );
      }
      return <Welcome crown={crown} />
    }

}

export default withStore(Home)
