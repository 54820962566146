import React from 'react'
import { Link } from 'react-router-dom'
import MaterialTable from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Button from '@material-ui/core/Button'

const Table = ({headers, data, onEdit, onDelete}) => (
  <MaterialTable>
    <TableHead>
      <TableRow>
      {
        headers.map((header, i) =>
          <TableCell key={i}>
            {header}
          </TableCell>
      )}
      <TableCell>
        Actions
      </TableCell>
      </TableRow>
    </TableHead>
    <TableBody>
      {
        data && data.map((entry, entryIndex) => (
          <TableRow key={entryIndex}>
            {
              headers.map((header, headerIndex) => (
                <TableCell key={headerIndex}>
                  {
                    header === "bottle" && entry[header].length ? (
                      <Link
                        to='/admin/bottles'>
                        {entry[header][0]["title"]}
                      </Link>
                    ) : (
                      header === "qrCode" ? (
                        <img src={entry[header]} height="50" alt="QR Code" />
                      ) : (
                        header === "account" && entry[header].length ? (
                          <Link
                            to='/admin/accounts'>
                            {entry[header][0]["title"]}
                          </Link>
                        ) : (
                          entry[header] && entry[header].toLocaleString()
                        )
                      )
                    )
                  }
                </TableCell>
              )
            )}
            <TableCell>
            {onEdit && (
              <Button
                size="small"
                color="primary"
                variant="outlined"
                className="btn"
                onClick={() => onEdit(entry)}>
                  Edit
              </Button>
            )}
            {onDelete && (
              <Button
                size="small"
                color="inherit"
                variant="outlined"
                className="btn"
                onClick={() => onDelete(entry)}>
                  Delete
              </Button>
            )}
            </TableCell>
          </TableRow>
        )
      )}
    </TableBody>
  </MaterialTable>
)

export default Table
