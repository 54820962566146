// import get from "lodash.get";
import { Grid } from '@material-ui/core';
import jsQR from "jsqr";
import React, { Component, createRef } from 'react';
import { debounce } from 'throttle-debounce';
import 'url-search-params-polyfill';


class Camera extends Component {

  constructor(props) {
    super(props);

    this.video = createRef();
    this.canvas = null;//createRef();
    this.ctx = null;

    this.camWidth = 400;
    this.camHeight = 600;
    this.cameraRefresh = 250;

    this.state = {
      crown: null,
    };

    // Debounce scan handler to prevent multiple consecutive submissions
    this.handleScanData = debounce(500, true, this.handleScanData);
  }

  getCanvas() {
    return this.canvas;
  }

  componentDidMount() {
    // TODO: DEBUG:
    window.fakeScan = (sku, uid) => {
      this.handleReceiveScan({ sku, uid });
    };
    if (this.checkHasCamera()) {
      // this.video = document.getElementById("Camera");
      // this.video.current.setAttribute('muted', '');
      // this.video.current.setAttribute("autoplay", '');
      // this.video.current.setAttribute("playsinline", '');

      this.canvas = document.createElement('canvas');
      this.canvas.width = this.camWidth;
      this.canvas.height = this.camHeight;
      this.ctx = this.canvas.getContext('2d');

      this.startCamera();
    }
  }

  checkHasCamera = () => {
    // Get access to the camera!
    return navigator.mediaDevices && navigator.mediaDevices.getUserMedia;
  };

  startCamera = () => {
    navigator.mediaDevices
      .getUserMedia({
        video: {
          facingMode: 'environment'
        }
      })
      .then(stream => {
        this.video.current.srcObject = stream;
        setInterval(this.handleCameraStream, this.cameraRefresh);
      });
  };

  handleCameraStream = () => {
    if (this.video.current) {
      this.ctx.drawImage(this.video.current, 0, 0);
      const imgData = this.ctx.getImageData(0, 0, this.camWidth, this.camHeight);
      this.handleScan(jsQR(imgData.data, this.camWidth, this.camHeight, {inversionAttempts: 'dontInvert'}));
    }
  };

  handleScan = (qrData) => {
    if (qrData && qrData.data) {
      this.handleScanData(qrData);
    }
  };

  handleScanData = (qrData) => {
    // Parse and unpack data/url
    const url = qrData.data;
    const qs = url.split('?')[1];
    const params = new URLSearchParams(qs);

    // Go and find the bottle from QR Code
    const sku = params.get('sku');
    const uid = params.get('uid');
    this.handleReceiveScan({ sku, uid });
  };

  handleReceiveScan = (data) => {
    this.props.onScan(data);
  }

  handleError = (err) => {
    console.error(err);
  };

  render() {
    return (
      <Grid id="CameraContainer" container className="camera_container">
        <div className="overlay" />
        <video ref={this.video} id="Camera" muted playsInline autoPlay className="camera" />
      </Grid>
    );
  }
}

export default Camera;
