import React, { Component, Fragment } from 'react'
import Copy from '../common/Copy'
import Camera from '../components/camera/Camera'
import CameraAccess from '../components/camera/CameraAccess'
import Toast from '../components/toast/Toast'
import { Page, Frame } from '../components/Page';

import { loadCrown, redeemCrown, validateCrownOpenState, claimGift, openCrown } from '../api';

import withStore from '../store';
import track from '../track';

class Scan extends Component {

    state = {
      isCameraEnabled: false,
      isToastOpen: false,
      toastVariant: "begin",
    }

    componentDidMount() {
      // TODO: DEBUG:
      window.fakeML = () => {
        this.shouldFakeML = true;
      };
      this.requestCameraPermissions()
    }


    requestCameraPermissions = () => {
      // Does the app have permission to use camera?
      if (navigator && navigator.permissions) {
        return navigator.permissions.query({name: 'camera'}).then((result) => {
          if (result.state === "granted") {
            this.setState({
              isCameraEnabled: true,
              isToastOpen: true
            })
          }
        })
      } else {
        this.setState({
          isCameraEnabled: true,
          isToastOpen: true
        })
      }
    }

    handleRequestCamera = () => {
      if (navigator) {
        return navigator.mediaDevices.getUserMedia({video: true})
          .then((p) => {
            this.setState({
              isCameraEnabled: true,
              isToastOpen: true
            })
          }).catch((err) => {
            // Camera denied
          })
      }
    }

    handleToastClose = () => {
      this.setState({
        isToastOpen: false
      })
    }

    openToast = (val) => {
      this.setState({
        isToastOpen: true,
        toastVariant: val
      })
    }

    checkIsBottleOpen = async () => {
      if (!this.camera) {
        console.warn('no camera!');
        return;
      }
      const canvas = this.camera.getCanvas();
      const bytes = canvas.toDataURL('image/jpeg', 0.9).replace('data:image/jpeg;base64,', '');

      if (this.shouldFakeML) {
        console.info('ML endpoint request faked');
        return true;
      }

      const data = await validateCrownOpenState(bytes);
      if (data.status === 'ok') {
        return data.crown === 'off';
      }
    }

    handleScan = async (data) => {
      const { store, updateStore } = this.props;
      const isFirstScan = store.scans.length === 0;
      const crown = await loadCrown({ uid: data.uid })
      if (!crown) {
        this.openToast('invalid');
        return;
      }
      this.setState({ crown: crown }); // TODO: Do we need this?
      const isBottleAvailable = crown.redeemable;
      // if it is the first scan
      if (isFirstScan) {
        if (!isBottleAvailable) {
          // Bottle has already been redeemed
          this.openToast('taken');
          return;
        }
        const isBottleOpen = await this.checkIsBottleOpen();
        if (!isBottleOpen) {
          // User needs to open bottle
          this.openToast('closed');
          return;
        }
        // Redeem bottle
        this.openToast('success');
        redeemCrown({ uid: data.uid });
        openCrown({ uid: data.uid });
        updateStore({ scans: [data.uid], giftCount: 1 });
        track('claim gift', 1);
        // Show success message
        this.showNextGift();
        return;
      }
      // not the first scan
      const hasUserUsedBottleAlready = store.scans.indexOf(data.uid) !== -1;
      if (hasUserUsedBottleAlready) {
        this.openToast('alreadyClaimed');
        return;
      }
      this.openToast('claim');
      // TODO: Load the user from the state
      await claimGift({ userUid: store.scans[0], claimUid: crown.uid });
      const giftCount = store.giftCount + 1;
      updateStore({ scans: [...store.scans, data.uid], giftCount: giftCount });
      track('claim gift', giftCount);
      this.showNextGift();
    }

    showNextGift = () => {
      this.props.history.push(`/gifts/${this.props.store.giftCount}`);
    }

    render() {

      const { isCameraEnabled, isToastOpen, toastVariant } = this.state

      return(
        <Fragment>
          <Page>
            <Frame>
              {isCameraEnabled ?
                (<Camera ref={(el) => (this.camera = el)} onScan={this.handleScan} />) :
                (
                  <CameraAccess
                    handleRequestCamera={this.handleRequestCamera}
                    copy={Copy.userScan}
                  />
                )
              }
            </Frame>
          </Page>
          <Toast
            copy={Copy.userScan}
            isCameraEnabled={isCameraEnabled}
            isToastOpen={isToastOpen}
            toastVariant={toastVariant}
            handleToastClose={this.handleToastClose} />
        </Fragment>
      )
    }
}

export default withStore(Scan)
