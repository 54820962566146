import React, { Component, Fragment } from 'react'

import Paper from '@material-ui/core/Paper'
import Container from '@material-ui/core/Container'
import Snackbar from '@material-ui/core/Snackbar'
import CloseIcon from '@material-ui/icons/Close'
import IconButton from '@material-ui/core/IconButton'
import Button from '@material-ui/core/Button'
import ButtonGroup from '@material-ui/core/ButtonGroup'

import Config from '../../common/Config'
import Loading from '../../components/loading/Loading'
import AdminHeader from '../../components/adminHeader/AdminHeader'
import Table from '../../components/table/Table'
import ErrorBoundary from '../../components/errorBoundary/ErrorBoundary'
import './AdminList.scss';


class Logs extends Component {

    state = {
      token: localStorage.getItem('auth_token') || null,
      isLoaded: false,
      isError: false,
      headers: [
        "name",
        "value",
        "identity",
      ],
      logs: [],
      account: {},
      isSnackbarOpen: false,
      snackBarMessage: "",

      itemsLimit: 20,
      itemsTotal: null,
      pagesTotal: null,
      prevPage: null,
      nextPage: null,
      currentPage: 1
    }

    componentDidMount() {
      this.handleFetchLogs()
    }

    handleFetchLogs = (page = this.state.currentPage) => {
      fetch(`${Config.apiEndpoint}/log?limit=${this.state.itemsLimit}&page=${page}`, {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      })
      .then(res => res.json())
      .then(
        (data) => {
          this.setState({
            isLoaded: true,
            isError: false,
            logs: data.items,
            itemsTotal: data.total,
            pagesTotal: data.totalPages,
            prevPage: data.prevPage,
            nextPage: data.nextPage,
            currentPage: data.page
          })
        },
        (error) => {
          this.setState({
            isLoaded: true,
            isError: true
          })
        }
      )
    }


    handleOnChange = (e) => {
      const target = e.target
      const value = target.type === 'checkbox' ? target.checked : target.value
      const name = target.name
      const {account} = {...this.state}
      account[name] = value
      this.setState({
        account
      })
    }

    handleCloseSnackbar = () => {
      this.setState({
        isSnackbarOpen: false,
        snackBarMessage: ""
      })
    }

    renderPagination = () => {

      const goToPage = (page) => {
        this.setState({currentPage: page});
        this.handleFetchLogs(page);
        window && window.scrollTo(0, 0);
      }
      const goToFirstPage = () => goToPage(1);
      const goToLastPage = () => goToPage(this.state.pagesTotal);
      const goToPreviousPage = () => goToPage(this.state.currentPage - 1);
      const goToNextPage = () => goToPage(this.state.currentPage + 1);
      const isFirstPage = this.state.currentPage === 1;
      const isLastPage = this.state.currentPage === this.state.pagesTotal;
      const shouldRender = this.state.pagesTotal > 1;

      return (
        shouldRender &&
        <div className="AdminList__pagination">
          <ButtonGroup variant="contained">
            <Button disabled={isFirstPage} onClick={goToFirstPage}>First page</Button>
            <Button disabled={isFirstPage} onClick={goToPreviousPage}>Previous page</Button>
            <Button disabled className="AdminList__pagination-count">Page {this.state.currentPage} of {this.state.pagesTotal}</Button>
            <Button disabled={isLastPage} onClick={goToNextPage}>Next page</Button>
            <Button disabled={isLastPage} onClick={goToLastPage}>Last page</Button>
          </ButtonGroup>
        </div>
      );
    }

    render() {

      const {
        isLoaded,
        logs,
        headers,
        isSnackbarOpen,
        snackBarMessage,
        itemsTotal
      } = this.state

      return (
        <Fragment>
          <AdminHeader />
          <Container className="AdminList white-bg">
            <Fragment>
              <section className="page-header">
                <h1>Logs ({itemsTotal || '…'})</h1>
              </section>
              <ErrorBoundary>
              {
                logs || isLoaded ? (
                  <Fragment>
                    <Paper>
                      <Table
                        headers={headers}
                        data={logs}
                        onEdit={null}
                        onDelete={null} />
                    </Paper>

                    { this.renderPagination() }

                  </Fragment>
                ) : (
                  <Loading />
                )
              }
              </ErrorBoundary>
            </Fragment>
          </Container>
          <Snackbar
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            open={isSnackbarOpen}
            onClose={this.handleCloseSnackbar.bind(this)}
            autoHideDuration={3000}
            ContentProps={{
              'aria-describedby': 'message-id',
            }}
            message={snackBarMessage}
            action={[
              <IconButton
                key="close"
                aria-label="close"
                color="inherit"
                onClick={this.handleCloseSnackbar.bind(this)}
              >
              <CloseIcon />
            </IconButton>
          ]}
          />
        </Fragment>
      )

    }

}

export default Logs
