import React from 'react'
import { Center } from '../Page';
import { PrimaryButton } from '../Button';
import { TextBubble } from '../Text';

const CameraAccess = ({copy,handleRequestCamera}) => (
  <Center>
    <PrimaryButton onClick={handleRequestCamera}>{copy.btnLabel}</PrimaryButton>
    <TextBubble>
      <p>{copy.enable}</p>
    </TextBubble>
  </Center>
)

export default CameraAccess
