import React, { forwardRef, Fragment } from 'react'
import Paper from '@material-ui/core/Paper'
import Container from '@material-ui/core/Container'
import Button from '@material-ui/core/Button'
import Box from '@material-ui/core/Box'

const CrownForm = forwardRef(({crown, accounts, bottles, onSubmit, onChange, onClose}, ref) => (
  <Box
    ref={ref}
    className="Fullscreen"
    display="flex"
    alignItems="center"
    justifyContent="center">
    <Container maxWidth="sm">
      <Paper>
        <div className="padded">
          <form onSubmit={onSubmit}>
            <label>
              Account
            </label>
            <select
              className="field"
              required
              onChange={onChange}
              name="account">
              <option>--- Please select ---</option>
              {
                accounts.map((a,i) =>
                  <option
                    key={i}
                    selected={crown.account && crown.account.length ? crown.account[0]._id === a._id : false}
                    value={a._id}>
                      {a.title}
                  </option>
              )}
            </select>
            <label>
              Campaign Id
            </label>
            <input
              className="field"
              required
              autoFocus={true}
              autoComplete="off"
              type="text"
              name="campaignId"
              onChange={onChange}
              placeholder="Campaign Id"
              value={crown.campaignId || ''} />
            <label>
              Redirect Uri
            </label>
            <input
              className="field"
              autoComplete="off"
              type="url"
              name="redirectUri"
              placeholder="https://www.wearesharpend.com"
              onChange={onChange}
              value={crown.redirectUri || ''} />
            <label>
              Bottle
            </label>
            <select
              className="field"
              required
              disabled={crown._id !== undefined}
              onChange={onChange}
              name="bottle">
              <option>--- Please select ---</option>
              {
                bottles.map((b,i) =>
                  <option
                    key={i}
                    selected={crown.bottle && crown.bottle.length ? crown.bottle[0]._id === b._id : false}
                    value={b._id}>
                      {b.title}
                  </option>
              )}
            </select>
            <label>
              Media Type
            </label>
            <select
              className="field"
              required
              disabled={crown._id !== undefined}
              onChange={onChange}
              name="media">
                <option>
                  --- Please select ---
                </option>
                <option
                  selected={crown.media === "q"}
                  value="q">
                    QR Code
                </option>
                <option
                  selected={crown.media === "n"}
                  value="n">
                    NFC
                </option>
            </select>
            <label className="field">
              <input
                name="redeemable"
                type="checkbox"
                checked={crown.redeemable}
                onChange={onChange} />
                Redeemable
            </label>
            <label className="field">
              <input
                name="closed"
                type="checkbox"
                checked={crown.closed}
                onChange={onChange} />
              Closed
            </label>
            {
              crown._id === undefined && (
                <Fragment>
                <label>
                  How many crowns do you want to make?
                </label>
                <input
                  className="field"
                  required
                  autoComplete="off"
                  type="number"
                  name="iterations"
                  placeholder="100"
                  onChange={onChange} />
                </Fragment>
              )
            }
            <Button
              type="submit"
              variant="outlined"
              color="primary">
                Save
            </Button>
            <Button
              onClick={onClose}
              variant="outlined">
                Cancel
            </Button>
          </form>
        </div>
      </Paper>
    </Container>
  </Box>
))

export default CrownForm
