import React, { forwardRef } from 'react'
import Paper from '@material-ui/core/Paper'
import Container from '@material-ui/core/Container'
import Button from '@material-ui/core/Button'
import Box from '@material-ui/core/Box'

const AccountForm = forwardRef(({account, onSubmit, onChange, onClose}, ref) => (
  <Box
    ref={ref}
    className="Fullscreen"
    display="flex"
    alignItems="center"
    justifyContent="center">
    <Container maxWidth="sm">
      <Paper>
        <div className="padded">
          <form onSubmit={onSubmit}>
            <label>
              Title
            </label>
            <input
              className="field"
              required
              autoFocus={true}
              autoComplete="off"
              type="text"
              name="title"
              onChange={onChange}
              placeholder="InBev"
              value={account.title || ''} />
            <label>
              Slug
            </label>
            <input
              className="field"
              required
              autoComplete="off"
              type="text"
              name="slug"
              onChange={onChange}
              placeholder="InBev"
              value={account.slug || ''} />
            <Button
              type="submit"
              variant="outlined"
              color="primary">
                Save
            </Button>
            <Button
              onClick={onClose}
              variant="outlined">
                Cancel
            </Button>
          </form>
        </div>
      </Paper>
    </Container>
  </Box>
))

export default AccountForm
