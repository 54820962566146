import React from 'react'
import { CssBaseline } from '@material-ui/core'
import { MuiThemeProvider } from '@material-ui/core/styles'
import { Route, Redirect } from 'react-router-dom'
import { AnimatedSwitch } from 'react-router-transition'
import { theme } from './theme/Theme'

import Home from './pages/Home'
import Scan from './pages/Scan'
import Gift from './pages/Gift'
import Gifts from './pages/Gifts'
import Privacy from './pages/Privacy'
import Admin from './pages/admin'

const App = () => {
  return (
    <MuiThemeProvider theme={theme}>
      <CssBaseline>
        <AnimatedSwitch
          atEnter={{ offset: 100 }}
          atLeave={{ offset: -100 }}
          atActive={{ offset: 0 }}
          className="route-wrapper"
          mapStyles={(styles) => ({
            transform: `translateX(${styles.offset}%)`,
          })}
        >

          <Route exact path="/" component={Home} />

          <Route exact path="/scan" component={Scan} />
          <Route path="/gifts/:gift" component={Gift} />
          <Route path="/gifts" component={Gifts} />
          <Route exact path="/privacy" component={Privacy} />

          <Route path="/admin" component={Admin} />

          <Redirect to="/" />

        </AnimatedSwitch>
      </CssBaseline>
    </MuiThemeProvider>
  )
}

export default App
