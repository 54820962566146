import React from 'react'
import { NavLink } from 'react-router-dom'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import { ACTIONS, AuthProvider, useAuthDispatch, useAuthState } from '../../common/use-auth'
import Config from '../../common/Config';
import './AdminHeader.scss';

const AdminHeader = (props) => {
  const authDispatch = useAuthDispatch();
  const authState = useAuthState();

  const logout = async () => {

    await fetch(`${Config.apiEndpoint}/auth/logout`, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('auth_token') || null}`
      },
      method: 'POST'
    });

    authDispatch({type: ACTIONS.LOGOUT});
  }

  const userName = authState.user.name || authState.user.username;

  return (
    <AppBar position="static" className="AdminHeader">
      <Toolbar>
        <NavLink
          activeClassName="selected"
          className="nav-link"
          to="/admin/crowns">
          Crowns
        </NavLink>
        <NavLink
          activeClassName="selected"
          className="nav-link"
          to="/admin/bottles">
          Bottles
        </NavLink>
        <NavLink
          activeClassName="selected"
          className="nav-link"
          to="/admin/accounts">
          Accounts
        </NavLink>
        <NavLink
          activeClassName="selected"
          className="nav-link"
          to="/admin/users">
          Users
        </NavLink>
        <NavLink
          activeClassName="selected"
          className="nav-link"
          to="/admin/logs">
          Logs
        </NavLink>
        <div className="AdminHeader__grow"></div>
        <div className="AdminHeader__user">
          <AuthProvider>
            Hello {userName}. <button onClick={logout}>Log out</button>
          </AuthProvider>
        </div>
      </Toolbar>
    </AppBar>
  )
}

export default AdminHeader
