import React from 'react'
import { Redirect, Link } from 'react-router-dom';
import { Page, Content, Actions, RibbonTitle } from '../components/Page';
import { PrimaryButton } from '../components/Button';

import withStore from '../store';

import bauble1 from '../assets/images/bauble1.svg';
import bauble2 from '../assets/images/bauble2.svg';
import bauble3 from '../assets/images/bauble3.svg';
import bauble4 from '../assets/images/bauble4.svg';
import bauble5 from '../assets/images/bauble5.svg';

import css from './Gifts.module.css';

const FILLS = [
  bauble1,
  bauble2,
  bauble3,
  bauble4,
  bauble5,
];

const Bubble = ({ number, fill }) => {
  if (!fill) {
    return (
      <div className={css.Bubble}>
        <span>{number}</span>
      </div>
    );
  }
  return (
    <Link to={`/gifts/${number}`} className={css.Bubble} data-has-fill>
      <img src={fill} alt={number} />
    </Link>
  );
}

const Gifts = ({ store }) => {
  if (store.giftCount < 1) {
    return <Redirect to="/" />
  }
  return (
    <Page>
      <RibbonTitle>Your Gift</RibbonTitle>
      <Content>
        <div className={css.Bubbles}>
          <Bubble number={1} fill={store.giftCount >= 1 && FILLS[0]} />
          <Bubble number={2} fill={store.giftCount >= 2 && FILLS[1]} />
          <Bubble number={3} fill={store.giftCount >= 3 && FILLS[2]} />
          <Bubble number={4} fill={store.giftCount >= 4 && FILLS[3]} />
          <Bubble number={5} fill={store.giftCount >= 5 && FILLS[4]} />
        </div>
      </Content>
      <Actions size="short">
        <PrimaryButton href="/">Go Home</PrimaryButton>
      </Actions>
    </Page>
  );
};

export default withStore(Gifts)
