import { createLog } from './api';

let pending = [];
let timeout;

let identity = JSON.parse(localStorage.getItem('identity'));;

const getIdentity = () => {
  return identity || 'anonymous';
}

function processPending() {
  const events = pending;
  pending = [];
  createLog(events.map(([name, value='']) => ({
    name,
    value,
    identity: getIdentity(),
  })));
}


export default (...event) => {
  pending.push(event);
  clearTimeout(timeout);
  timeout = setTimeout(processPending, 50);
}

export const setIdentity = (id) => {
  identity = id;
  localStorage.setItem('identity', JSON.stringify(id));
}
