import React, { Component, Fragment } from 'react'

import Paper from '@material-ui/core/Paper'
import Container from '@material-ui/core/Container'
import Snackbar from '@material-ui/core/Snackbar'
import Modal from '@material-ui/core/Modal'
import CloseIcon from '@material-ui/icons/Close'
import IconButton from '@material-ui/core/IconButton'
import Button from '@material-ui/core/Button'
import ButtonGroup from '@material-ui/core/ButtonGroup'

import Config from '../../common/Config'
import Loading from '../../components/loading/Loading'
import AdminHeader from '../../components/adminHeader/AdminHeader'
import BottleForm from '../../components/bottleForm/BottleForm'
import Table from '../../components/table/Table'
import ErrorBoundary from '../../components/errorBoundary/ErrorBoundary'
import './AdminList.scss';


class Bottles extends Component {

    state = {
      token: localStorage.getItem('auth_token') || null,
      isLoaded: false,
      isError: false,
      isModalOpen: false,
      headers: [
        "account",
        "title",
        "sku",
        "redirectUri"
      ],
      bottles: [],
      accounts: [],
      bottle: {},
      isSnackbarOpen: false,
      snackBarMessage: "",

      itemsLimit: 20,
      itemsTotal: null,
      pagesTotal: null,
      prevPage: null,
      nextPage: null,
      currentPage: 1
    }

    componentDidMount() {
      this.handleFetchBottles()
      this.handleFetchAccounts()
    }

    handleFetchBottles = (page = this.state.currentPage) => {
      fetch(`${Config.apiEndpoint}/bottles?limit=${this.state.itemsLimit}&page=${page}`, {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      })
      .then(res => res.json())
      .then(
        (data) => {
          this.setState({
            isLoaded: true,
            isError: false,
            bottles: data.items,
            itemsTotal: data.total,
            pagesTotal: data.totalPages,
            prevPage: data.prevPage,
            nextPage: data.nextPage,
            currentPage: data.page
          })
        },
        (error) => {
          this.setState({
            isLoaded: true,
            isError: true
          })
        }
      )
    }

    handleFetchAccounts = () => {
      fetch(`${Config.apiEndpoint}/accounts`, {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      })
      .then(res => res.json())
      .then(
        (data) => {
          this.setState({
            accounts: data.items
          })
        },
        (error) => {
        }
      )
    }

    handleOpenModal = (bottle) => {
      this.setState({
        isModalOpen: true,
        bottle: bottle
      })
    }

    handleCloseModal = () => {
      this.setState({
        isModalOpen: false
      })
    }

    handleOnSubmit = (e) => {
      e.preventDefault()

      let newBottle,endpoint,method
      newBottle = this.state.bottle

      // Create a new entry or update existing
      if (newBottle._id === undefined) {
        endpoint = `${Config.apiEndpoint}/bottles`
        method = "POST"
      } else {
        endpoint = `${Config.apiEndpoint}/bottles/${newBottle._id}`
        method = "PUT"
      }

      fetch(endpoint, {
        method: method,
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${this.state.token}`
        },
        body: JSON.stringify(this.state.bottle)
      })
      .then(res => res.json())
      .then(
        (data) => {
          if (data.message === "Bottle created successfully" || data.message === "Bottle updated") {
            const {bottles} = {...this.state}
            bottles.unshift(data.bottle)
            this.setState({
              bottles: bottles,
              itemsTotal: data.message.indexOf('created') !== -1 ? this.state.itemsTotal + 1 : this.state.itemsTotal,
              isSnackbarOpen: true,
              snackBarMessage: "Bottle saved"
            })
            this.handleCloseModal()
          }
        },
        (error) => {
          this.setState({
            isError: true
          })
        }
      )
    }

    handleOnChange = (e) => {
      const target = e.target
      const value = target.type === 'checkbox' ? target.checked : target.value
      const name = target.name
      const {bottle} = {...this.state}
      bottle[name] = value
      this.setState({
        bottle
      })
    }

    handleOnDelete = (bottle) => {
      const {bottles} = {...this.state}
      fetch(`${Config.apiEndpoint}/bottles/${bottle._id}`, {
        method: "DELETE",
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${this.state.token}`
        }
      })
      .then(res => res.json())
      .then(
        (data) => {
          if (data.message === "Bottle deleted") {
            bottles.splice(bottles.indexOf(bottle),1)
            this.setState({
              bottles: bottles,
              itemsTotal: this.state.itemsTotal - 1,
              isSnackbarOpen: true,
              snackBarMessage: "Bottle deleted"
            })
          }
        },
        (error) => {
          this.setState({
            isError: true
          })
        }
      )
    }

    handleCloseSnackbar = () => {
      this.setState({
        isSnackbarOpen: false,
        snackBarMessage: ""
      })
    }

    renderPagination = () => {

      const goToPage = (page) => {
        this.setState({currentPage: page});
        this.handleFetchBottles(page);
        window && window.scrollTo(0, 0);
      }
      const goToFirstPage = () => goToPage(1);
      const goToLastPage = () => goToPage(this.state.pagesTotal);
      const goToPreviousPage = () => goToPage(this.state.currentPage - 1);
      const goToNextPage = () => goToPage(this.state.currentPage + 1);
      const isFirstPage = this.state.currentPage === 1;
      const isLastPage = this.state.currentPage === this.state.pagesTotal;
      const shouldRender = this.state.pagesTotal > 1;

      return (
        shouldRender &&
        <div className="AdminList__pagination">
          <ButtonGroup variant="contained">
            <Button disabled={isFirstPage} onClick={goToFirstPage}>First page</Button>
            <Button disabled={isFirstPage} onClick={goToPreviousPage}>Previous page</Button>
            <Button disabled className="AdminList__pagination-count">Page {this.state.currentPage} of {this.state.pagesTotal}</Button>
            <Button disabled={isLastPage} onClick={goToNextPage}>Next page</Button>
            <Button disabled={isLastPage} onClick={goToLastPage}>Last page</Button>
          </ButtonGroup>
        </div>
      );
    }

    render() {

      const {
        isLoaded,
        bottles,
        accounts,
        headers,
        isModalOpen,
        bottle,
        isSnackbarOpen,
        snackBarMessage,
        itemsTotal
      } = this.state

      return (
        <Fragment>
          <AdminHeader />
          <Container className="AdminList white-bg">
            <Fragment>
              <section className="page-header">
                <h1>Bottles ({itemsTotal || '…'})</h1>
                <div className="rightBtns">
                  <Button
                    onClick={() => this.handleOpenModal({})}
                    variant="outlined"
                    className="btn"
                    color="primary">
                    New Bottle
                  </Button>
                </div>
              </section>
              <ErrorBoundary>
              {
                bottles || isLoaded ? (
                  <Fragment>
                    <Paper>
                      <Table
                        headers={headers}
                        data={bottles}
                        onEdit={this.handleOpenModal.bind(this)}
                        onDelete={this.handleOnDelete.bind(this)} />
                    </Paper>

                    { this.renderPagination() }

                  </Fragment>
                ) : (
                  <Loading />
                )
              }
              </ErrorBoundary>
            </Fragment>
          </Container>
          <Modal
            open={isModalOpen}
            onClose={this.handleCloseModal.bind(this)}
          >
            <BottleForm
              bottle={bottle}
              accounts={accounts}
              onClose={this.handleCloseModal.bind(this)}
              onSubmit={this.handleOnSubmit.bind(this)}
              onChange={this.handleOnChange.bind(this)} />
          </Modal>
          <Snackbar
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            open={isSnackbarOpen}
            onClose={this.handleCloseSnackbar.bind(this)}
            autoHideDuration={3000}
            ContentProps={{
              'aria-describedby': 'message-id',
            }}
            message={snackBarMessage}
            action={[
              <IconButton
                key="close"
                aria-label="close"
                color="inherit"
                onClick={this.handleCloseSnackbar.bind(this)}
              >
              <CloseIcon />
            </IconButton>
          ]}
          />
        </Fragment>
      )

    }

}

export default Bottles
