import React from 'react';
import { Link } from 'react-router-dom';

import css from './Button.module.css';

const Button = ({ children, onClick, href, className, ...props }) => {
  if (href) {
    return <Link to={href} className={className || css.Button}>{children}</Link>
  }
  return <button onClick={onClick} className={className || css.Button} {...props}>{children}</button>
};

export const PrimaryButton = ({ children, ...props }) => (
  <Button className={css.PrimaryButton} {...props}>{children}</Button>
)

export { PrimaryButton as Button };

export const SecondaryButton = ({ children, ...props }) => (
  <Button className={css.SecondaryButton} {...props}>{children}</Button>
)
