import React from 'react'

import css from './Page.module.css';

export const Page = ({ children }) => (
  <div className={css.Page}>
    {children}
  </div>
)

export const Frame = ({ children }) => (
  <div className={css.Frame}>
    {children}
  </div>
)

export const Center = ({ children }) => (
  <div className={css.Center}>
    {children}
  </div>
)

export const Content = ({ children }) => (
  <div className={css.Content}>
    {children}
  </div>
)

export const Actions = ({ children, size="tall" }) => (
  <div className={css.Actions} data-size={size}>
    {children}
  </div>
)

export const RibbonTitle = ({ children }) => (
  <div className={css.RibbonTitle}>
    <span className={css.RibbonTitle__inner}>{children}</span>
  </div>
)
