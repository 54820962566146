import Config from './common/Config'

export const loadCrown = ({ uid }) => {
  return fetch(`${Config.apiEndpoint}/uids/${uid}`, {
    method: 'GET',
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    }
  })
  .then(res => res.json())
  .then(res => res.crown)
  .catch(error => {
    console.error('API Error: loadCrown failed', error);
  })
}


export const redeemCrown = ({ uid }) => {
  const data = {
    redeemable: false,
  };
  return fetch(`${Config.apiEndpoint}/uids/${uid}`, {
    method: 'PUT',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
  .then(res => res.json())
}


export const claimGift = ({ userUid, claimUid }) => {
  return fetch(`${Config.apiEndpoint}/uids/${userUid}/claims`, {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ uid: claimUid })
  })
  .then(res => res.json())
}


export const validateCrownOpenState = (imageBytes) => {
  const data = {
    payload: {
      image: {
        imageBytes: imageBytes
      }
    }
  };
  return fetch(`${Config.apiEndpoint}/crowns/validate`, {
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    method: 'POST',
    body: JSON.stringify(data)
  })
  .then(res => res.json())
  ;
}

export const openCrown = ({ uid }) => {
  const data = {
    closed: false,
  };
  return fetch(`${Config.apiEndpoint}/uids/${uid}`, {
    method: 'PUT',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
  .then(res => res.json())
}

export const createLog = (payload) => {
  return fetch(`${Config.apiEndpoint}/log`, {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(payload)
  })
  .then(res => res.json())
}
