import React from 'react';

import css from './Media.module.css';

export const Media = ({ children }) => {
  return (
    <div style={{position: 'relative'}} className={css.Media}>
      <div className={css.Media__content}>
        {children}
      </div>
    </div>
  )
};

export const Video = ({ children, loop }) => {
  return (
    <Media>
      <video autoPlay controls={!loop} loop={loop} playsInline={loop} muted={loop}>
        <source src={children} type="video/mp4" />
        Sorry, your browser doesn't support embedded videos.
      </video>
    </Media>
  )
};

export const Image = ({ children }) => {
  return (
    <div className={css.Image}>
      <img src={children} alt="" className={css.Image__content} />
    </div>
  )
};

export const YouTube = ({ children, ...props }) => {
  return (
    <Media {...props}>
      <iframe
        src={children}
        frameBorder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowFullscreen
        title="embed"
      ></iframe>
    </Media>
  )
};
