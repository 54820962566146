import React, { forwardRef } from 'react'
import Paper from '@material-ui/core/Paper'
import Container from '@material-ui/core/Container'
import Button from '@material-ui/core/Button'
import Box from '@material-ui/core/Box'

const BottleForm = forwardRef(({bottle, accounts, onSubmit, onChange, onClose}, ref) => (
  <Box
    ref={ref}
    className="Fullscreen"
    display="flex"
    alignItems="center"
    justifyContent="center">
    <Container maxWidth="sm">
      <Paper>
        <div className="padded">
          <form onSubmit={onSubmit}>

            <label>
              Account
            </label>
            <select
              className="field"
              required
              onChange={onChange}
              name="account">
              <option>--- Please select ---</option>
              {
                accounts.map((a,i) =>
                  <option
                    key={i}
                    selected={bottle.account && bottle.account.length ? bottle.account[0]._id === a._id : false}
                    value={a._id}>
                      {a.title}
                  </option>
              )}
            </select>

            <label>
              Title
            </label>
            <input
              className="field"
              required
              autoFocus={true}
              autoComplete="off"
              type="text"
              name="title"
              onChange={onChange}
              placeholder="InBev bottle"
              value={bottle.title || ''} />

            <label>
              SKU (max length 2 characters)
            </label>
            <input
              className="field"
              required
              autoComplete="off"
              type="text"
              name="sku"
              maxLength="2"
              placeholder="AB"
              onChange={onChange}
              value={bottle.sku || ''} />

            <label>
              Redirect URI
            </label>
            <input
              className="field"
              required
              autoComplete="off"
              type="text"
              name="redirectUri"
              placeholder="http://..."
              onChange={onChange}
              value={bottle.redirectUri || ''} />

            {/*
            <label>
              Cap Distance
            </label>
            <input
              className="field"
              required
              autoComplete="off"
              type="number"
              name="capDistance"
              placeholder="1.4"
              onChange={onChange}
              value={bottle.capDistance || ''} />
            <label>
              Bottle Colour (r,g,b)
            </label>
            <input
              className="field"
              required
              autoComplete="off"
              type="text"
              name="bottleColour"
              placeholder="255,255,255"
              onChange={onChange}
              value={bottle.bottleColour || ''} />
            <label>
              Cap Colour (r,g,b)
            </label>
            <input
              className="field"
              autoComplete="off"
              type="text"
              name="capColour"
              placeholder="255,255,255"
              onChange={onChange}
              value={bottle.capColour || ''} />
            */}

            <Button
              type="submit"
              variant="outlined"
              color="primary">
                Save
            </Button>
            <Button
              onClick={onClose}
              variant="outlined">
                Cancel
            </Button>
          </form>
        </div>
      </Paper>
    </Container>
  </Box>
))

export default BottleForm
