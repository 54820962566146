import React from 'react'
import { Page, Content, Actions, RibbonTitle } from '../components/Page';
import { SecondaryButton } from '../components/Button';
import { TextBubble } from '../components/Text';

const Privacy = () => {
  return (
    <Page>
      <RibbonTitle>Your Privacy</RibbonTitle>
      <Content>
        <TextBubble>
          <p>Disclaimer:</p>
          <p>1) This website collects data (phone brand, when users scan, repeat rate, shares) but not any personally identifiable data.</p>
          <p>2) The data will be compiled into a dashboard (in a way that cannot be traced back to individuals) and shared within AB-Inbev to promote QR scanning technology.</p>
          <p>3) The raw data, will be collected by Sharpend, will not shared with anyone outside of that company and deleted once it’s compiled.</p>
          <p>4) This concept is only being trialed internally (GITEC) and it will only be active until 14th Dec.</p>
        </TextBubble>
      </Content>
      <Actions>
        <br />
        <SecondaryButton onClick={() => window.history.back()}>Back</SecondaryButton>
      </Actions>
    </Page>
  );
}

export default Privacy
