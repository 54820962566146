import React, { Component } from 'react';
import { EventEmitter } from 'fbemitter';

const emitter = new EventEmitter();

const savedStore = JSON.parse(localStorage.getItem('store'));

let store = savedStore || {
  scans: [],
  giftCount: 0,
};

// Save to the local storage
emitter.addListener('change', () => {
  localStorage.setItem('store', JSON.stringify(store));
});

const useStore = (WrappedComponent) => {
  return class extends Component {
    constructor(props) {
      super(props);
      this.updateStore = this.updateStore.bind(this);
      this.handleChange = this.handleChange.bind(this);
    }
    componentDidMount() {
      this.subscription = emitter.addListener('change', this.handleChange);
    }
    componentWillUnmount() {
      this.subscription.remove();
    }
    handleChange() {
      this.setState({
        random: Math.random(),
      });
    }
    updateStore(data) {
      store = {
        ...store,
        ...data,
      };
      emitter.emit('change');
    }
    render() {
      return <WrappedComponent updateStore={this.updateStore} store={store} {...this.props} />;
    }
  }
}

export const getStoreState = () => {
  return store;
};

export default useStore;
