module.exports = {
  loginPage: {
    message: "Hey, bartender! Log in to earn points in our exclusive on-trade experience",
    btnLabel: "Log In"
  },
  userScan: {
    enable: "You must allow camera access to scan bottles and unlock gifts",
    btnLabel: "Allow camera access",
    successBtnLabel: "Redeem points",
    toast: {
      begin: "Look for the QR code on the bottle neck and scan to start earning points",
      missing: "Align the QR code to the box highlighted",
      taken: "Hmmm, that's someone else's bottle...",
      opened: "Hmmm. This bottle has already been recorded as open. Try another",
      invalid: "Hmmm. This bottle code was not recognised",
      error: "Hmmm. This bottle has already been scanned",
      closed: "Oh! Oh! Oh no… It looks like you’ve been a naughty beer lover and haven’t open your beer yet!",
      success: "That's an open bottle. Click to redeem your points!",
      redeemed: "Congrats, your points were redeemed!",
      claim: "A new gift!",
      alreadyClaimed: "Hmmm, looks like you already Scanned this bottle"
    }
  }
}
