import React, { createContext, useContext, useReducer } from 'react';
// import createPersistedState from 'use-persisted-state';

const ACTIONS = {
  LOGIN: 'AUTH_LOGIN',
  LOGOUT: 'AUTH_LOGOUT',
  CHECK: 'AUTH_CHECK'
};

const TOKEN_KEY = 'auth_token';
const USER_KEY = 'auth_user';
// const usePersistedState = createPersistedState(TOKEN_KEY);

const AuthStateContext = createContext();
const AuthDispatchContext = createContext();

const getInitialState = () => {
  const user = JSON.parse(localStorage.getItem(USER_KEY)) || {};
  const token = localStorage.getItem(TOKEN_KEY) || null;
  const active = token && user;

  return {
    user,
    active,
    error: null,
    token,
    fetching: false
  };
}

const authReducer = (state, action) => {

  switch (action.type) {

    case ACTIONS.LOGIN:
      localStorage.setItem(USER_KEY, JSON.stringify(action.payload.user));
      localStorage.setItem(TOKEN_KEY, action.payload.token);
      return {
        ...state,
        active: true,
        user: action.payload.user,
        token: action.payload.token
      };

    case ACTIONS.LOGOUT:
      localStorage.clear();
      return {
        ...state,
        active: false,
        user: null,
        token: null
      };

    case ACTIONS.CHECK:
      // return authToken ? {...state, active: true} : {...state, active: false};// TODO
      return {...state, active: true};

    default: {
      throw new Error(`Could not handle action: ${action}`);
    }
  }
}

const AuthProvider = ({children}) => {
  const [state, dispatch] = useReducer(authReducer, getInitialState());

  return (
    <AuthStateContext.Provider value={state}>
      <AuthDispatchContext.Provider value={dispatch}>
        {children}
      </AuthDispatchContext.Provider>
    </AuthStateContext.Provider>
  )
}

const useAuthState = () => {
  const context = useContext(AuthStateContext);

  if (context === undefined) {
    throw new Error('useAuthState must be used with an AuthProvider');
  }

  return context;
}

const useAuthDispatch = () => {
  const context = useContext(AuthDispatchContext);

  if (context === undefined) {
    throw new Error('useAuthDispatch must be used within an AuthProvider');
  }

  return context;
}

const useAuth = () => {
  return [useAuthState(), useAuthDispatch()];
}

export default useAuth;
export {ACTIONS, AuthProvider, useAuthState, useAuthDispatch};
