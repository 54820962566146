import React from 'react'
import { Route, Redirect } from 'react-router-dom'

import { useAuthState, AuthProvider } from '../../common/use-auth'

import Accounts from './Accounts'
import Crowns from './Crowns'
import Bottles from './Bottles'
import Users from './Users'
import Logs from './Logs'
import AdminLogin from './AdminLogin'

const AdminRoute = ({ component: Component, ...rest}) => {
  const {active} = useAuthState();

  return (
    <Route
      {...rest}
      render={ props =>
        active ? (
          <Component {...props} />
        ) : (
          <Redirect to={{
            pathname: '/admin/login',
            state: { referer: props.location}
          }} />
        )
      } />
  )
}

const AdminRouter = () => {
  if (process.env.REACT_APP_DISABLE_ADMIN) {
    return null;
  }
  return (
    <AuthProvider>
      <AdminRoute exact path="/admin/accounts" component={Accounts} />
      <AdminRoute exact path="/admin/crowns" component={Crowns} />
      <AdminRoute exact path="/admin/bottles" component={Bottles} />
      <AdminRoute exact path="/admin/users" component={Users} />
      <AdminRoute exact path="/admin/logs" component={Logs} />
      <AdminRoute exact path="/admin" component={Crowns} />
      <Route exact path="/admin/login" component={AdminLogin} />
    </AuthProvider>
  );
};

export default AdminRouter;
