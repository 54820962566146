import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import { Page, Frame, Content, Actions } from '../components/Page';
import { PrimaryButton, SecondaryButton } from '../components/Button';
import { TextBubble } from '../components/Text';

import titleImage from '../assets/images/title.png';

import css from './Welcome.module.css';

import withStore from '../store';

const Title = ({ size="" }) => (
  <img src={titleImage} alt="" className={css.Title} data-size={size} />
)

class Welcome extends Component {

    renderFirst() {
      return (
        <Page>
          <Content>
            <Title size="medium" />
            <TextBubble>
              <h1>Welcome</h1>
              <p>Scan your QR code again to unlock a festive gift. Press below to start</p>
            </TextBubble>
          </Content>
          <Actions>
            <PrimaryButton href="/scan">Scan & Unlock</PrimaryButton>
            <Link className={css.privacyLink} to="/privacy">Privacy Policy</Link>
          </Actions>
        </Page>
      )
    }

    renderSubsequent() {
      return (
        <Page>
          <Content>
            <Title size="medium" />
            <TextBubble>
              <h1>Welcome Back!</h1>
              <p>Unlock more festive gifts - find your friends’ bottles and scan them too</p>
            </TextBubble>
          </Content>
          <Actions>
            <PrimaryButton href="/scan">Scan & Unlock</PrimaryButton>
            <SecondaryButton href="/gifts">My Gifts</SecondaryButton>
            <Link className={css.privacyLink} to="/privacy">Privacy Policy</Link>
          </Actions>
        </Page>
      )
    }

    renderBlank() {
      // TODO: Add frame
      return (
        <Page>
          <Frame>
            <Title size="large" />
          </Frame>
        </Page>
      );
    }

    render() {
      // TODO: Figure this out based on local storage
      const { store, crown } = this.props;
      const isFirstScan = store.scans.length === 0;
      if (isFirstScan) {
        if (!crown) {
          // If no uid or invalid uid
          return this.renderBlank();
        }
        return this.renderFirst();
      }
      return this.renderSubsequent();
    }

}

export default withStore(Welcome)
