import React from 'react'
import {Close as CloseIcon,SentimentVeryDissatisfied as SentimentVeryDissatisfiedIcon,Mood as MoodIcon} from '@material-ui/icons'
import {Snackbar,IconButton} from '@material-ui/core'

const Toast = ({isToastOpen, handleToastClose, toastVariant, copy}) => {

  let message,style,icon,action
  let duration = 10000

  switch(toastVariant) {
    case "begin":
      message = copy.toast.begin
      break
    case "missing":
      message = copy.toast.missing
      break
    case "taken":
      message = copy.toast.taken
      style = "toast-warning"
      icon = <SentimentVeryDissatisfiedIcon className="white-text" />
      break
    case "invalid":
      message = copy.toast.invalid
      style = "toast-warning"
      icon = <SentimentVeryDissatisfiedIcon className="white-text" />
      break
    case "error":
      message = copy.toast.error
      style = "toast-warning"
      icon = <SentimentVeryDissatisfiedIcon className="white-text" />
      break
    case "closed":
      message = copy.toast.closed
      break
    case "success":
      message = copy.toast.success
      style = "toast-success"
      icon = <MoodIcon className="white-text" />
      duration = 50000
      break
    case "redeemed":
      message = copy.toast.redeemed
      style = "toast-success"
      icon = <MoodIcon className="white-text" />
      duration = 5000
      break
    case "claim":
      message = copy.toast.claim
      style = "toast-success"
      icon = <MoodIcon className="white-text" />
      duration = 5000
      break
    case "alreadyClaimed":
      message = copy.toast.alreadyClaimed
      style = "toast-warning"
      icon = <SentimentVeryDissatisfiedIcon className="white-text" />
      duration = 5000
      break
    default:
      message = copy.toast[toastVariant]
      break
  }

  return (
    <Snackbar
      anchorOrigin={{
        vertical: "bottom",
        horizontal: 'center',
      }}
      open={isToastOpen}
      onClose={handleToastClose}
      autoHideDuration={duration}
      ContentProps={{
        'aria-describedby': 'message-id',
      }}
      message={
        <span className="toast-content">
          {icon}
          <span className="toast-message">
            {message}
          </span>
          <span className="toast-action">
            {action}
          </span>
        </span>
      }
      className={style}
      action={[
          <IconButton
            key="close"
            aria-label="close"
            onClick={handleToastClose}
          >
          <CloseIcon />
        </IconButton>
      ]}
    />
  )
}

export default Toast
