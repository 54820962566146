import React, { useState } from 'react'
import { Redirect } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Config from '../../common/Config';
import useAuth, { ACTIONS } from '../../common/use-auth';
import './AdminLogin.scss';

const AdminLogin = (props) => {

  const initialState = {
    username: '',
    password: '',
    isSubmitting: false,
    errorMessage: null
  };
  const [formData, setFormData] = useState(initialState);
  const [authState, authDispatch] = useAuth();
  const referer = (props.location && props.location.state && props.location.state.referer) || '/admin';

  const handleInputChange = event => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value
    });
  };

  const handleFormSubmit = async event => {

    event.preventDefault();

    const {username, password} = formData;

    try {
      const response = await fetch(`${Config.apiEndpoint}/auth/login`, {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        method: 'POST',
        body: JSON.stringify({
          username,
          password
        })
      });

      const responseJson = await response.json();

      if (responseJson.status === 'ok') {
        const user = responseJson.user;
        const token = responseJson.token;
        authDispatch({type: ACTIONS.LOGIN, payload: {user, token}})
      } else {
        setFormData({
          ...formData,
          isSubmitting: false,
          errorMessage: responseJson.message
        });
      }
    } catch (error) {
      setFormData({
        ...formData,
        isSubmitting: false,
        errorMessage: error.message || error.statusText
      });
    }
  }

  if (authState.active) {
    return <Redirect to={referer} />;
  }

  return (
    <Container className="AdminLogin" maxWidth="sm">

      <Typography variant="h2" gutterBottom>
        Admin
      </Typography>

      <Paper className="AdminLogin__panel">

        {
          formData.errorMessage &&
          <div className="AdminLogin__error">{formData.errorMessage}</div>
        }

        <form className="AdminLogin__form" onSubmit={handleFormSubmit}>

          <TextField
            className="AdminLogin__input"
            variant="outlined"
            margin="normal"
            fullWidth
            type="text"
            label="Username"
            name="username"
            defaultValue={formData.username}
            onChange={handleInputChange}
            required
            autoFocus />

          <TextField
            className="AdminLogin__input"
            variant="outlined"
            margin="normal"
            fullWidth
            type="password"
            name="password"
            label="Password"
            defaultValue={formData.password}
            onChange={handleInputChange}
            required />

          <Button
            className="AdminLogin__button"
            color="primary"
            fullWidth
            type="submit"
            disabled={formData.isSubmitting}>
            { formData.isSubmitting ? 'Loading...' : 'Log in' }
          </Button>

        </form>

      </Paper>
    </Container>

  );
}

export default AdminLogin;
