import React from 'react'
import { Redirect } from 'react-router-dom';
import { Page, Content, Actions, RibbonTitle } from '../components/Page';
import { PrimaryButton, SecondaryButton } from '../components/Button';
import { TextBubble } from '../components/Text';
import { Video, Image, YouTube } from '../components/Media';

import withStore from '../store';
import track from '../track';

import gift1 from '../assets/gifts/1.mp4';
import gift2 from '../assets/gifts/2.jpg';
import gift3 from '../assets/gifts/3.mp4';
import gift5 from '../assets/gifts/5.mp4';

const gift4 = 'https://www.youtube.com/embed/P8Ol7SQVFG4';

const MAX_COUNT = 5;

const GIFTS = [
  (<Video>{gift1}</Video>),
  (<Image>{gift2}</Image>),
  (<Video loop>{gift3}</Video>),
  (<YouTube>{gift4}</YouTube>),
  (<Video>{gift5}</Video>),
];

const triggerShare = () => {
  try {
    navigator.share({
      url: window.location.href,
    })
      .then((result) => {
        track('shared', result);
      })
      .catch(() => {
        track('cancelled share');
      })
    ;
  } catch(error) {
    // let slip
  }
}

const Gift = ({ store, match }) => {
  const currentGift = parseInt(match.params.gift, 10);
  const canShare = 'share' in window.navigator;
  if (currentGift > store.giftCount) {
    return <Redirect to="/gifts" />
  }
  // TODO: If being shared, change text to "Enjoy Your Gift" in script with decoration
  return (
    <Page>
      <RibbonTitle>Your Gift</RibbonTitle>
      <Content>
        {GIFTS[currentGift - 1]}
        <TextBubble>
          {store.giftCount >= MAX_COUNT ?
            (<p>Nice! You’ve collected all the gifts! Happy Holidays!</p>) :
            (<p>Go and scan your friends’ bottles to unlock more gifts! There’s {MAX_COUNT - store.giftCount} more to collect.</p>)
          }
        </TextBubble>
      </Content>
      <Actions>
        {canShare ? (<PrimaryButton onClick={triggerShare}>Share</PrimaryButton>) : null}
        <SecondaryButton href="/gifts">Back</SecondaryButton>
      </Actions>
    </Page>
  );
}

export default withStore(Gift)
